import React from "react"
import Modal from "react-modal"

Modal.setAppElement("#___gatsby")

const ReactModal = ({ className, ...props }) => {
  const contentClassName = `${className}__content`
  const overlayClassName = `${className}__overlay`
  return (
    <Modal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  )
}

export default ReactModal
