import React, { useEffect } from "react"
import PropTypes from "prop-types"
import "normalize.css"
import "typeface-roboto"

import { graphql } from "gatsby"
import { createGlobalStyle, ThemeProvider } from "styled-components"

// import SEO from "../components/seo"
import Header from "./header"
import Footer from "./footer"
import SEO from "./seo"

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    position: relative;
    min-height: 100%;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body {
    min-height: 100%;
    font-family: "Roboto",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    line-height: 1.625;
    color: #505050;
    letter-spacing: 1px;
    font-weight: 300;
  }
  
  a {
    color: inherit;
    text-decoration: none;
    transition: opacity 250ms ease-in-out;
    
    &:hover {
      opacity: 0.5;
    }
  }
  
  h3 {
    font-size: 1.25rem;
  }
`

const theme = {
  textColor: "#505050",
  textColorEmphasis: "#000",
  textColorLight: "#666",
  headerHeightMobile: "6rem",
  headerHeight: "4rem",
  colorPrimary: "#C51C5E",
  colorSecondary: "#ff7971",
  colorMono: "#787878",
  selectedWorkWidth: "18rem",
}

const Layout = ({ children, header, pageMeta, footer }) => {
  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    const resizeListener = isFirst => {
      const vh = window.innerHeight * 0.01
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }
    // set resize listener
    resizeListener()
    window.addEventListener("resize", resizeListener)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener)
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SEO
        title={pageMeta.pageTitle}
        description={pageMeta.pageDescription}
        image={pageMeta.pageImage}
        uri={pageMeta.uri}
        siteName={header.siteName}
      />
      <Header data={header} />
      <main>{children}</main>
      <Footer data={footer} />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.object.isRequired,
  pageMeta: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
    pageDescription: PropTypes.string.isRequired,
    pageImage: PropTypes.object.isRequired,
    uri: PropTypes.string,
  }),
  footer: PropTypes.object.isRequired,
}

export default Layout

export const query = graphql`
  fragment HeaderInformation on ContentfulHeader {
    logo {
      description
      localFile {
        publicURL
      }
    }
    siteName
    headerTitle
    links {
      text
      url
    }
    ctaButtonLink
    ctaButtonText
  }

  fragment FooterInformation on ContentfulFooter {
    footerTitle {
      footerTitle
    }
    ctaButtonLink
    ctaButtonText
    address {
      address
    }
    addressLink
    phone
    email
    facebookLink
    instagramLink
    linkedinLink
    twitterLink
    weChatLink {
      localFile {
        childImageSharp {
          fixed(width: 128) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    copyright {
      copyright
    }
  }
`
