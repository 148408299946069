import React, { useState } from "react"
import styled from "styled-components"
import { WidthContainer } from "./widthContainer"
import HeaderLogo from "./headerLogo"
import { Link } from "gatsby"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

const Container = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: ${props =>
    props.nearTop ? "none" : "rgba(255,255,255,0.5)"};
  backdrop-filter: ${props => (props.nearTop ? "none" : "blur(8px)")};
  box-shadow: ${props =>
    props.nearTop ? "none" : "0 0 3px 0 rgba(0, 0, 0, 0.1)"};
  transition: 500ms ease-in-out;

  @media screen and (min-width: 768px) {
    display: block;
  }
`

const InnerContainer = styled(WidthContainer)`
  display: flex;
`

const Spacer = styled.div`
  flex: 1;
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
`

const NavLink = styled(Link)`
  display: block;
  padding: 0.5rem 1rem;
`

const NavLinkText = styled.div`
  font-weight: 400;
`

const NavLinkButtonContainer = styled(Link)`
  display: block;
  border: 2px solid ${props => props.theme.textColorEmphasis};
  padding: 0.5rem 1rem;
  margin-left: 1rem;
`
const NavLinkButton = styled.div`
  color: ${props => props.theme.textColorEmphasis};
  font-weight: 500;
  text-transform: uppercase;
`

const HeaderMenu = ({
  data: { logo, links, ctaButtonLink, ctaButtonText },
}) => {
  const [nearTop, setNearTop] = useState(true)

  useScrollPosition(({ prevPos, currPos }) => {
    setNearTop(currPos.y > -4 * 16)
  })

  return (
    <Container nearTop={nearTop ? 1 : 0}>
      <InnerContainer>
        <HeaderLogo logo={logo} />
        <Spacer />
        <Nav>
          {links.map((link, index) => (
            <NavLink key={index} to={link.url}>
              <NavLinkText>{link.text}</NavLinkText>
            </NavLink>
          ))}
          <NavLinkButtonContainer to={ctaButtonLink}>
            <NavLinkButton>{ctaButtonText}</NavLinkButton>
          </NavLinkButtonContainer>
        </Nav>
      </InnerContainer>
    </Container>
  )
}

export default HeaderMenu
