/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, title, image, uri, siteName }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            twitter
            siteUrl
          }
        }
      }
    `
  )

  const imageFile = image.localFile.childImageSharp.fixed
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${siteName}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: "og:url",
          content: `${site.siteMetadata.siteUrl}${uri}/`,
        },
        {
          name: "og:locale",
          content: lang,
        },
        {
          name: "og:image",
          content: `${site.siteMetadata.siteUrl}${imageFile.src}`,
        },
        {
          name: "og:image:width",
          content: imageFile.width,
        },
        {
          name: "og:image:height",
          content: imageFile.height,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.twitter,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.twitter,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  uri: "",
}

SEO.propTypes = {
  description: PropTypes.string.isRequired,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  uri: PropTypes.string,
  siteName: PropTypes.string.isRequired,
}

export default SEO
