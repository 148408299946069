// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { WidthContainer } from "./widthContainer"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import { FiMap, FiPhone, FiMail } from "react-icons/fi"

import {
  FaLinkedin,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaWeixin,
  FaSearch,
} from "react-icons/fa"
// import { TextContainer } from "./textContainer"
import { InView } from "react-intersection-observer"

const Container = styled.footer``

const ActionContainer = styled.section`
  background: ${props => props.theme.colorPrimary};
  color: #fff;
  padding-top: 5rem;
  padding-bottom: 5rem;
`

const ActionInnerContainer = styled.div`
  margin-left: 1rem;

  @media (min-width: 576px) {
    margin-left: 2rem;
  }
`

const ActionTitle = styled.h2`
  white-space: pre-line;
  font-size: 2.25rem;
  margin: 0;
  text-transform: uppercase;
  line-height: 1.4;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 500ms ease-in-out;
`

const ActionButton = styled(Link)`
  display: inline-block;
  padding: 0.5rem 1.5rem;
  border: 1px solid #fff;
  margin-top: 3rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 500;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 500ms ease-in-out;
  transition-delay: 250ms;
`

const MainFooter = styled.section`
  background: ${props => props.theme.colorMono};
  padding-top: 5rem;
  padding-bottom: 3rem;
  color: #fff;
`

const ContactContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 0 1rem;

  @media (min-width: 576px) {
    margin-left: 2rem;
  }
`

const ContactLine = styled.li`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  &:first-child {
    margin-top: 0;
  }
`

const ContactIcon = styled.div`
  width: 3rem;
`

const ContactInfo = styled.a`
  white-space: pre-line;
  font-size: 0.875rem;
`

const MapIcon = styled(FiMap)`
  vertical-align: middle;
  stroke-width: 1;
`
const PhoneIcon = styled(FiPhone)`
  vertical-align: middle;
  stroke-width: 1;
`

const MailIcon = styled(FiMail)`
  vertical-align: middle;
  stroke-width: 1;
`

const SearchIcon = styled(FaSearch)``

const SocialContainer = styled.div`
  margin-top: 3rem;
  margin-left: 1rem;

  @media (min-width: 576px) {
    margin-left: 2rem;
  }
`

const SocialInnerContainer = styled.div`
  margin: -1rem;
  display: flex;
  flex-wrap: wrap;
`

const SocialButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #424242;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  border-radius: 50%;
  margin: 0.5rem;
`

const WechatLinkContainer = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate3d(-50%, -1rem, 0);
  display: none;
  background: ${props => props.theme.textColor};
  padding: 0.25rem;

  &:after {
    position: absolute;
    bottom: -0.75rem;
    left: 50%;
    content: "";
    border-left: 0.75rem solid transparent;
    border-right: 0.75rem solid transparent;
    border-top: 0.75rem solid ${props => props.theme.textColor};
    transform: translate3d(-50%, 0, 0);
  }
`

const WechatLinkText = styled.div``
const WechatLinkTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 1em;
  padding: 0 0.5rem;
  font-size: 0.75rem;
  margin: 0.5rem 0.25rem;
`

const WechatButtonContainer = styled.div`
  margin: 0.5rem;
  position: relative;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    //opacity: 0.5;
    ${WechatLinkContainer} {
      display: block;
    }
  }
`

const WechatButton = styled.div`
  background: #424242;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.5;
  }
`

const FacebookIcon = styled(FaFacebook)`
  display: block;
  //vertical-align: middle;
  //stroke-width: 1;
`
const InstagramIcon = styled(FaInstagram)`
  display: block;
  //vertical-align: middle;
  //stroke-width: 1;
`
const LinkedinIcon = styled(FaLinkedin)`
  display: block;
  //vertical-align: middle;
  //stroke-width: 1;
`

const TwitterIcon = styled(FaTwitter)`
  display: block;
  //vertical-align: middle;
  //stroke-width: 1;
`

const WechatIcon = styled(FaWeixin)`
  display: block;
  //vertical-align: middle;
  //stroke-width: 1;
`

const WechatLink = styled(Img)`
  display: block;
`

const Copyright = styled.div`
  margin-top: 5rem;
  text-align: center;
  font-size: 0.75rem;
  white-space: pre-line;
`

const Footer = ({
  data: {
    ctaButtonLink,
    ctaButtonText,
    footerTitle: { footerTitle },
    address: { address },
    addressLink,
    phone,
    email,
    facebookLink,
    instagramLink,
    linkedinLink,
    twitterLink,
    weChatLink,
    copyright: { copyright },
  },
}) => {
  return (
    <Container>
      <InView triggerOnce threshold={0.5}>
        {({ inView, ref, entry }) => (
          <ActionContainer ref={ref}>
            <WidthContainer>
              <ActionInnerContainer>
                <ActionTitle active={inView ? 1 : 0}>{footerTitle}</ActionTitle>
                <ActionButton active={inView ? 1 : 0} to={ctaButtonLink}>
                  {ctaButtonText}
                </ActionButton>
              </ActionInnerContainer>
            </WidthContainer>
          </ActionContainer>
        )}
      </InView>
      <MainFooter>
        <WidthContainer>
          <ContactContainer>
            {address && (
              <ContactLine>
                <ContactIcon>
                  <MapIcon size={"1.5rem"} />
                </ContactIcon>
                <ContactInfo
                  href={addressLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {address}
                </ContactInfo>
              </ContactLine>
            )}
            {phone && (
              <ContactLine>
                <ContactIcon>
                  <PhoneIcon size={"1.5rem"} />
                </ContactIcon>
                <ContactInfo href={`tel:${phone}`}>{phone}</ContactInfo>
              </ContactLine>
            )}
            {email && (
              <ContactLine>
                <ContactIcon>
                  <MailIcon size={"1.5rem"} />
                </ContactIcon>
                <ContactInfo href={`mailto:${email}`}>{email}</ContactInfo>
              </ContactLine>
            )}
          </ContactContainer>
          <SocialContainer>
            <SocialInnerContainer>
              {facebookLink && (
                <SocialButton
                  href={facebookLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon size={"1.5rem"} />
                </SocialButton>
              )}
              {instagramLink && (
                <SocialButton
                  href={instagramLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon size={"1.5rem"} />
                </SocialButton>
              )}
              {linkedinLink && (
                <SocialButton
                  href={linkedinLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedinIcon size={"1.5rem"} />
                </SocialButton>
              )}
              {twitterLink && (
                <SocialButton
                  href={twitterLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon size={"1.5rem"} />
                </SocialButton>
              )}
              {WechatLink && (
                <WechatButtonContainer>
                  <WechatButton>
                    <WechatIcon size={"1.5rem"} />
                  </WechatButton>
                  <WechatLinkContainer>
                    <WechatLink
                      fixed={weChatLink.localFile.childImageSharp.fixed}
                      style={{ display: "block" }}
                    />
                    <WechatLinkTextContainer>
                      <WechatLinkText>Sensible Lab</WechatLinkText>
                      <SearchIcon />
                    </WechatLinkTextContainer>
                  </WechatLinkContainer>
                </WechatButtonContainer>
              )}
            </SocialInnerContainer>
          </SocialContainer>
          {copyright && <Copyright>{copyright}</Copyright>}
        </WidthContainer>
      </MainFooter>
    </Container>
  )
}

export default Footer
