import React from "react"
import HeaderMobileMenu from "./headerMobileMenu"
import styled from "styled-components"
import HeaderMenu from "./headerMenu"

const Container = styled.header``

const Header = ({ data }) => (
  <Container>
    <HeaderMobileMenu data={data} />
    <HeaderMenu data={data} />
    {/*<div*/}
    {/*  style={{*/}
    {/*    margin: `0 auto`,*/}
    {/*    maxWidth: 960,*/}
    {/*    padding: `1.45rem 1.0875rem`,*/}
    {/*  }}*/}
    {/*>*/}
    {/*  <h1 style={{ margin: 0 }}>*/}
    {/*    <Link*/}
    {/*      to="/"*/}
    {/*      style={{*/}
    {/*        color: `white`,*/}
    {/*        textDecoration: `none`,*/}
    {/*      }}*/}
    {/*    >*/}
    {/*      {siteTitle}*/}
    {/*    </Link>*/}
    {/*  </h1>*/}
    {/*</div>*/}
  </Container>
)

Header.propTypes = {}

Header.defaultProps = {}

export default Header
