import styled from "styled-components"

export const WidthContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  
  @media screen and (min-width: 576px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
  @media screen and (min-width: 768px) {
    max-width: 768px;
  }

  @media screen and (min-width: 992px) {
    max-width: 992px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1200px;
  }

  @media screen and (min-width: 1400px) {
    max-width: 1400px;
  }
`
