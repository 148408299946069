import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"
import { Link } from "gatsby"

const Logo = styled.img`
  display: block;
  width: 8rem;
  height: 2.25rem;
`

const HeaderContainer = styled.div`
  height: ${props => props.theme.headerHeightMobile};
  min-height: ${props => props.theme.headerHeightMobile};
  display: flex;
  align-items: center;

  @media screen and (min-width: 768px) {
    height: ${props => props.theme.headerHeight};
    min-height: ${props => props.theme.headerHeight};
  }
`

const LogoLink = styled(Link)`
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media screen and (min-width: 768px) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: ${props => (props.hidemd ? "none" : "block")};
  }
`

const HeaderLogo = ({ logo, hideMd }) => {
  return (
    <HeaderContainer>
      <LogoLink to={"/"} hidemd={hideMd ? 1 : 0}>
        <Logo alt={logo.description} src={logo.localFile.publicURL} />
      </LogoLink>
    </HeaderContainer>
  )
}

HeaderLogo.propTypes = {
  logo: PropTypes.object.isRequired,
  hideMd: PropTypes.bool,
}

HeaderLogo.defaultProps = {
  hideMd: false,
}

export default HeaderLogo
