import React, { useState } from "react"
import styled, { createGlobalStyle, css, keyframes } from "styled-components"
import ReactModal from "./reactModal"
import { Link } from "gatsby"

const MIN_BACKGROUND_OPACITY = 0.75
const DELAY_CONTAINER_DIFF = 100
const ANIMATION_CONTAINER_LENGTH = 300
const ANIMATION_INNER_CONTAINER_LENGTH = 300
const DELAY_INNER_CONTAINER_DIFF = 50

const FixedGlobalStyle = createGlobalStyle`
@media screen and (max-width: 767px){
  body {
    overflow: hidden;
  }
}
`

const ToggleContainer = styled.button`
  position: fixed;
  display: block;
  top: 1.5rem;
  right: 0.5rem;
  padding: 0.5rem;
  height: 3rem;
  width: 3rem;
  margin: 0;
  border: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 100;

  @media screen and (min-width: 576px) {
    right: 1.5rem;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const MobileMenuButtonBox = styled.span`
  width: 2rem;
  height: 2rem;
  display: block;
  position: relative;
  margin: auto;
`

const MobileMenuButtonInner = styled.span`
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 2rem;
  height: 3px;
  background-color: ${props => (props.active ? "#fff" : props.theme.textColor)};
  border-radius: 2px;
  position: absolute;
  transform: ${props => (props.active ? "rotate(225deg)" : null)};

  transition: transform 0.22s ${props => (props.active ? "0.12s" : null)}
      ${props =>
        props.active
          ? "cubic-bezier(0.215, 0.61, 0.355, 1)"
          : "cubic-bezier(0.55, 0.055, 0.675, 0.19)"},
    background-color 250ms ${props => (props.active ? "250ms" : null)}
      ease-in-out;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 2rem;
    height: 3px;
    background-color: ${props =>
      props.active ? "#fff" : props.theme.textColor};
    border-radius: 2px;
    position: absolute;
    //transition: transform 0.15s ease;
  }

  &:before {
    top: ${props => (props.active ? 0 : "-12px")};
    opacity: ${props => (props.active ? 0 : null)};
    transition: ${props =>
      props.active
        ? "top 0.1s ease-out, transform 0.15s ease, opacity 0.1s 0.12s ease-out, background-color 250ms 250ms ease-in-out"
        : "top 0.1s 0.25s ease-in, transform 0.15s ease, opacity 0.1s ease-in, background-color 250ms ease-in-out"};
  }

  &:after {
    bottom: ${props => (props.active ? 0 : "-12px")};
    transform: ${props => (props.active ? "rotate(-90deg)" : null)};
    transition: ${props =>
      props.active
        ? "bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 250ms 250ms ease-in-out"
        : "bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 250ms ease-in-out"};
  }
`

const MobileMenu = styled(ReactModal)`
  color: #fff;

  @media screen and (min-width: 768px) {
    display: none;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    overflow: hidden;
  }

  &__content {
    width: 100%;
    height: 100%;
    outline: none;
    display: flex;
    flex-direction: column;

    @media screen and (orientation: landscape) {
      flex-direction: row;
    }
  }
`

const containerIn = keyframes`
  from {
  transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`

const containerInLandscape = keyframes`
  from {
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`

const containerInCss = css`
  animation: ${containerIn} ${ANIMATION_CONTAINER_LENGTH}ms both ease-out;
  animation-delay: ${props => props.index * DELAY_CONTAINER_DIFF}ms;

  @media screen and (orientation: landscape) {
    animation: ${containerInLandscape} ${ANIMATION_CONTAINER_LENGTH}ms both
      ease-out;
    animation-delay: ${props => (props.index - 1) * DELAY_CONTAINER_DIFF}ms;
  }
`

const containerOut = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
`

const containerOutLandscape = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 100%, 0);
  }
`

const containerOutCss = css`
  animation: ${containerOut} ${ANIMATION_CONTAINER_LENGTH}ms both ease-out;
  animation-delay: ${props => props.index * DELAY_CONTAINER_DIFF}ms;

  @media screen and (orientation: landscape) {
    animation: ${containerOutLandscape} ${ANIMATION_CONTAINER_LENGTH}ms both
      ease-out;
    animation-delay: ${props => (props.index - 1) * DELAY_CONTAINER_DIFF}ms;
  }
`

const HeaderContainer = styled.div`
  flex: 1;
  background: rgba(0, 0, 0, 1);
  overflow: hidden;
  width: 100%;
  ${props => (props.active ? containerInCss : containerOutCss)}

  @media screen and (orientation: landscape) {
    display: none;
  }
`

const LinkContainer = styled(Link)`
  flex: 1;
  display: block;
  background: rgba(0, 0, 0, ${props => props.opacity});
  overflow: hidden;
  width: 100%;

  ${props => (props.active ? containerInCss : containerOutCss)}

  @media screen and (orientation: landscape) {
    width: inherit;
    height: 100%;
  }

  &:hover {
    opacity: 1;
  }
`
const innerContainerIn = keyframes`
  from {
  transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`

const innerContainerInLandscape = keyframes`
  from {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`

const innerContainerOut = keyframes`
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
`

const innerContainerOutLandscape = keyframes`
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
`

const innerContainerOutCss = css`
  animation: ${innerContainerOut} ${ANIMATION_INNER_CONTAINER_LENGTH}ms both
    ease-out;
  animation-delay: ${props => props.index * DELAY_INNER_CONTAINER_DIFF}ms;

  @media screen and (orientation: landscape) {
    animation: ${innerContainerOutLandscape}
      ${ANIMATION_INNER_CONTAINER_LENGTH}ms both ease-out;
    animation-delay: ${props =>
      (props.index - 1) * DELAY_INNER_CONTAINER_DIFF}ms;
  }
`

const innerContainerInCss = css`
  animation: ${innerContainerIn} ${ANIMATION_INNER_CONTAINER_LENGTH}ms both
    ease-out;
  animation-delay: ${props => props.index * DELAY_INNER_CONTAINER_DIFF}ms;

  @media screen and (orientation: landscape) {
    animation: ${innerContainerInLandscape}
      ${ANIMATION_INNER_CONTAINER_LENGTH}ms both ease-out;
    animation-delay: ${props =>
      (props.index - 1) * DELAY_INNER_CONTAINER_DIFF}ms;
  }
`

const LinkInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 2rem;
  ${props => (props.active ? innerContainerInCss : innerContainerOutCss)}

  @media screen and (min-width: 576px) {
    margin-left: 2rem;
  }

  @media screen and (orientation: landscape) {
    margin-left: 0;
    justify-content: center;
    padding-top: ${props => props.theme.headerHeightMobile};
  }
`

const LinkText = styled.div`
  font-size: 2rem;
  white-space: nowrap;
`

const LinkButton = styled.div`
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
  border: 1px solid #fff;
  text-align: center;

  @media screen and (orientation: landscape) {
    margin: 0 1rem;
  }
`

const HeaderMobileMenu = ({
  data: { links, ctaButtonLink, ctaButtonText },
}) => {
  const [active, setActive] = useState(false)

  const opacityDiff = (1 - MIN_BACKGROUND_OPACITY) / (links.length + 1)

  return (
    <>
      <ToggleContainer
        aria-label="Toggle navigation"
        aria-expanded={String(active)}
        onClick={() => {
          setActive(!active)
        }}
      >
        <MobileMenuButtonBox>
          <MobileMenuButtonInner active={active} />
        </MobileMenuButtonBox>
      </ToggleContainer>
      <MobileMenu
        isOpen={active}
        closeTimeoutMS={
          ANIMATION_CONTAINER_LENGTH + DELAY_CONTAINER_DIFF * (links.length + 1)
        }
      >
        <FixedGlobalStyle />
        <HeaderContainer index={0} active={active ? 1 : 0} />
        {links.map((link, index) => (
          <LinkContainer
            key={index}
            opacity={1 - opacityDiff * (index + 1)}
            index={index + 1}
            active={active ? 1 : 0}
            to={link.url}
            onClick={() => {
              setActive(!active)
            }}
          >
            <LinkInnerContainer active={active ? 1 : 0} index={index + 1}>
              <LinkText>{link.text}</LinkText>
            </LinkInnerContainer>
          </LinkContainer>
        ))}
        <LinkContainer
          opacity={MIN_BACKGROUND_OPACITY}
          index={links.length + 1}
          active={active ? 1 : 0}
          to={ctaButtonLink}
          onClick={() => {
            setActive(!active)
          }}
        >
          <LinkInnerContainer active={active ? 1 : 0} index={links.length + 1}>
            <LinkButton>{ctaButtonText}</LinkButton>
          </LinkInnerContainer>
        </LinkContainer>
      </MobileMenu>
    </>
  )
}

HeaderMobileMenu.defaultProps = {
  data: { links: [] },
}

export default HeaderMobileMenu
